//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { handleTaskBack } from '@/api/modular/flowable/handleTaskManage'
import { flowableInstanceHisUserTasks } from '@/api/modular/flowable/instanceManage'
export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      columns: [
        {
          title: '環節名稱',
          dataIndex: 'name'
        },
        {
          title: '操作人',
          dataIndex: 'assigneeName'
        }
      ],
      visible: false,
      confirmLoading: false,
      spinningLoading: false,
      tableLoading: false,
      recordData: {},
      form: this.$form.createForm(this),
      loadData: [],
      selectedRowKeys: []
    }
  },
  methods: {
    /**
     * 初始化方法
     */
    open (record) {
      this.recordData = record
      this.visible = true
      this.flowableInstanceHisUserTasks()
    },
    /**
     * 獲取節點列表
     */
    flowableInstanceHisUserTasks () {
      this.spinningLoading = true
      flowableInstanceHisUserTasks({ id: this.recordData.procIns.id }).then((res) => {
        this.spinningLoading = false
        if (res.data.length > 0) {
          let index = 1
          res.data.forEach(item => {
            item.keys = index++
            this.loadData.push(item)
          })
        }
      })
    },
    /**
     * 節點列表選擇
     */
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    /**
     * 提交
     */
    handleSubmit () {
      const { form: { validateFields } } = this
      if (this.selectedRowKeys.length < 1) {
        this.$message.warning('請選擇退回節點')
        return
      }
      validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = true
          values.taskId = this.recordData.id
          values.targetActId = this.selectedRows[0].id
          // eslint-disable-next-line eqeqeq
          values.targetActName = this.loadData.filter(item => item.keys == this.selectedRowKeys[0])[0].name
          handleTaskBack(values).then((res) => {
            if (res.success) {
              this.$message.success('退回成功')
              this.returnPage()
            } else {
              this.$message.error('退回失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        }
      })
    },
    /**
     * 返回並清空界面緩存
     */
    returnPage () {
      this.handleCancel()
      this.$emit('close')
    },
    /**
     * 關閉
     */
    handleCancel () {
      this.loadData = []
      this.form.resetFields()
      this.visible = false
    }
  }
}
