import { axios } from '@/utils/request'

/**
 * 查詢流程實例
 *
 * @author yubaoshan
 * @date 2020/6/9 1:01
 */
export function flowableInstancePage (parameter) {
  return axios({
    url: '/flowableInstance/page',
    method: 'get',
    params: parameter
  })
}

/**
 * 查詢流程實例的歷史任務節點，用於退回時選擇節點
 *
 * @author yubaoshan
 * @date 2020/6/9 1:01
 */
export function flowableInstanceHisUserTasks (parameter) {
  return axios({
    url: '/flowableInstance/hisUserTasks',
    method: 'get',
    params: parameter
  })
}

/**
 * 流程實例的流程圖，顯示當前節點和走過的節點
 *
 * @author yubaoshan
 * @date 2020/6/9 1:01
 */
export function flowableInstanceTrace (parameter) {
  return axios({
    url: '/flowableInstance/trace',
    method: 'get',
    params: parameter
  })
}

/**
 * 掛起流程實例
 *
 * @author yubaoshan
 * @date 2020/6/9 1:03
 */
export function flowableInstanceSuspend (parameter) {
  return axios({
    url: '/flowableInstance/suspend',
    method: 'post',
    data: parameter
  })
}

/**
 * 激活流程實例
 *
 * @author yubaoshan
 * @date 2020/6/9 1:03
 */
export function flowableInstanceActive (parameter) {
  return axios({
    url: '/flowableInstance/active',
    method: 'post',
    data: parameter
  })
}

/**
 * 流程實例的審批記錄
 *
 * @author yubaoshan
 * @date 2020/6/9 1:03
 */
export function flowableInstanceCommentHistory (parameter) {
  return axios({
    url: '/flowableInstance/commentHistory',
    method: 'get',
    params: parameter
  })
}

/**
 * 終止流程實例
 *
 * @author yubaoshan
 * @date 2020/8/11 14:50
 */
export function flowableInstanceEnd (parameter) {
  return axios({
    url: '/flowableInstance/end',
    method: 'post',
    data: parameter
  })
}

/**
 * 查詢我的流程實例
 *
 * @author yubaoshan
 * @date 2020/9/9 23:56
 */
export function flowableInstanceMy (parameter) {
  return axios({
    url: '/flowableInstance/my',
    method: 'get',
    params: parameter
  })
}

/**
 * 獲取流程實例中表單填寫的數據
 *
 * @author yubaoshan
 * @date 2020/9/9 00:19
 */
export function flowableInstanceFormData (parameter) {
  return axios({
    url: '/flowableInstance/formData',
    method: 'get',
    params: parameter
  })
}

/**
 * 加簽人員選擇器
 *
 * @author yubaoshan
 * @date 2020/9/12 01:42
 */
export function flowableInstanceAddSignUserSelector (parameter) {
  return axios({
    url: '/flowableInstance/addSignUserSelector',
    method: 'get',
    params: parameter
  })
}

/**
 * 減簽人員選擇器
 *
 * @author yubaoshan
 * @date 2020/9/12 01:42
 */
export function flowableInstanceDeleteSignUserSelector (parameter) {
  return axios({
    url: '/flowableInstance/deleteSignUserSelector',
    method: 'get',
    params: parameter
  })
}
